.site-layout {
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensures layout takes full viewport height */
}

.site-layout-header {
  height: 64px; 
  background: #fff; 
}

.site-layout-footer {
  height: 60px; 
  text-align: center;
  background: #fff; 
}

.site-layout-content {
  height: calc(99vh - 64px - 60px);
  padding: 24px;
  background: #fafafa;
  overflow-y: auto; 
}

.logo {
  height: 50px;
  margin: 16px;
  background-image: url("../../../assets/images/logo3.png");
  background-repeat: no-repeat;
  margin-left: 28px;
  margin-top: 16px;
  margin-bottom: 25px;
  margin-right: 52px;
  display: flex;
}

.ant-layout-header {
  background: #fff !important;
}

.ant-menu-dark {
  background: #021528 !important;
}

.ant-menu-dark .ant-menu-item > span > a {
  color: #fff !important;
}

.activeClass {
  background-color: #1890ff;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.topnav-right {
  float: right;
  padding-right: 10px;
}

.topnav-right > .ant-tag {
  color: #000;
  font-size: 15px;
  padding: 10px;
  border-radius: 30px;
  border: 1px solid #000;
}
