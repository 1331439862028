// @import 'assets/styles/mixins.scss';

@import "../style.module.scss";

.promo {
  color: $text-darken-more;
  text-align: center;
  margin-bottom: rem(60);

  h1 {
    text-transform: inherit;
    font-weight: 700;
  }

  p {
    font-size: rem(16);
  }
}

.contentHolder {
  text-align: left;
  padding: rem(50) rem(40) rem(20);
  background-color: $white;
  position: relative;
  border-radius: 50px;
  @media (max-width: 768px) {
    padding: rem(30) rem(20) rem(10);
  }
}

.mailaddress {
  color: #648e66 !important;
}

.backgroundWhite {
  background-color: white;
}

.ctfhireoffer {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.ctfHire {
  margin-bottom: 0 !important;
}
.cmsContentContianer {
  padding: rem(60) 0;
  min-height: calc(100vh - rem(100)); 
  background-color: #f2f4f8;
  display: flex;
  flex-direction: column;
}
.ctfHire {
  flex: 1; 
}

.footer {
  height: rem(100);
}

@media (max-width: 768px) {
  .cmsContentContianer {
    padding: rem(30) 0; 
  }
  .promo p {
    font-size: rem(14); 
  }
}