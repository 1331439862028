:global(body.modalOpen #root) {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

:global(body.modalOpen .ant-modal-mask) {
  background-color: rgba(184, 190, 202, 0.50)
}

.btn-danger-alt {
  color: #ff6868;
  background: #ffd8d8;
  border-color: #ffd8d8;
}

.btn-danger-alt:disabled, .btn-danger-alt:hover, .btn-danger-alt:focus, .btn-danger-alt:active, .btn-danger-alt.active {
  color: #fff;
  background: #ff6868;
  border-color: #ff6868;
}

.btn-light-alt {
  color: #495463;
  background: #e6effb;
  border-color: #e6effb;
}

.btn-light-alt:disabled, .btn-light-alt:hover, .btn-light-alt:focus, .btn-light-alt:active, .btn-light-alt.active {
  color: #fff;
  background: #495463;
  border-color: #495463;
}

.data-action-list {
  display: inline-flex;
  list-style: none;
}

.data-action-list li {
  padding: 0 5px
}

.data-action-list li:first-child {
  padding-left: 0
}

.data-action-list li:last-child {
  padding-right: 0
}

.data-action-dropdown {
  position: relative
}

.user-tnx .dt-amount, .user-tnx .dt-usd-amount, .user-tnx .dt-account {
  display: none
}

.user-tnx .dt-type-text, .user-tnx .dt-type-md {
  display: none
}

.data-col {
  padding: 20px 10px 20px 0;
  border-bottom: 1px solid #e6effb
}

.data-table:not(.dt-init) .data-item:last-child .data-col {
  border-bottom: none;
  padding-bottom: 5px
}

.data-col:last-child {
  padding-right: 0
}

.data-col .lead {
  font-size: 13px;
  font-weight: 600;
  color: #495463;
  letter-spacing: 0.03em;
  line-height: 1;
  display: block;
  margin-top: 0
}

.data-col .sub {
  font-size: 10px;
  font-weight: 400;
  color: #758698;
  letter-spacing: 0.01em;
  line-height: 1;
  padding-top: 7px;
  display: block
}

.data-col .sub-s2 {
  font-size: 12px;
  color: #495463;
  padding-top: 0
}

.data-col .sub:first-child {
  padding-top: 0
}

.data-item {
  background: #fff;
  margin-bottom: 3px;
  border-radius: 4px;
  position: relative
}

.data-head .data-col {
  padding: 0 10px 0 0;
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: #2c80ff;
  text-transform: uppercase;
  border-bottom: none
}

.data-state {
  height: 30px;
  width: 30px;
  line-height: 28px;
  margin-right: 12px;
  flex-shrink: 0
}

.data-state:after {
  display: inline-block;
  font-family: 'FontAwesome';
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  color: #2c80ff;
  height: 100%;
  width: 100%;
  font-size: 14px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid
}

.data-state-sm {
  height: 24px;
  width: 24px;
  line-height: 23px
}

.data-state-sm:after {
  font-size: 12px
}

.data-state-approved:after {
  content: '\f00c';
  color: #00d285;
  border-color: #00d285
}

.data-state-pending:after {
  content: '\f071';
  color: #ffc100;
  border-color: #ffc100
}

.data-state-canceled:after {
  content: '\f00d';
  color: #ff6868;
  border-color: #ff6868
}

.data-state-progress:after {
  content: '\f017';
  color: #1babfe;
  border-color: #1babfe
}

.data-state-missing:after {
  content: '\f128';
  color: #b1becc;
  border-color: #b1becc
}

.cms-page-heading {
  margin-bottom: 30px !important; 
}

.maksab-color {
  color: #658f66 !important;
}

@media (min-width: 420px) {
  .user-tnx .dt-type-sm {
    display: none
  }
  .user-tnx .dt-type-text, .user-tnx .dt-type-md {
    display: inline-block
  }
}

@media (min-width: 576px) {
  .user-tnx .dt-amount {
    display: table-cell
  }
  .admin-tnx .dt-amount {
    display: table-cell
  }
}

@media (min-width: 992px) {
  .user-tnx .dt-account {
    display: table-cell
  }
  .user-tnx .dt-usd-amount {
    display: table-cell
  }
  .admin-tnx .dt-account {
    display: table-cell
  }
  .admin-tnx .dt-usd-amount {
    display: table-cell
  }
}

.badge-md {
  padding: 6px 12px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  min-width: 90px
}

.btn {
  height: auto !important;
  line-height: normal !important;
}

.metamask-button {
  margin-top: 20px;
  width: 200px;
  height: 49px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(https://raw.githubusercontent.com/MetaMask/TipButton/master/images/1_pay_mm_off.png);
  cursor: pointer;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0.3rem;
}

.metamask-button:hover {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: 0;
  width: 200px;
  height: 49px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(https://raw.githubusercontent.com/MetaMask/TipButton/master/images/1_pay_mm_over.png);
}

.ant-form-item-label>label::after {
  margin: 0 8px 0 0px !important;
}

.solid-strikethrough {
  text-decoration: line-through;
  text-decoration-color: red;
  text-decoration-style: solid;
  -moz-text-decoration-color: red;
  -moz-text-decoration-style: solid;
  -webkit-text-decoration-color: red;
  -webkit-text-decoration-style: solid;
}

.double-strikethrough {
  text-decoration: line-through;
  text-decoration-color: blue;
  text-decoration-style: double;
  -moz-text-decoration-color: blue;
  -moz-text-decoration-style: double;
  -webkit-text-decoration-color: blue;
  -webkit-text-decoration-style: double;
}

.dotted-strikethrough {
  text-decoration: line-through;
  text-decoration-color: red;
  text-decoration-style: dotted;
  -moz-text-decoration-color: red;
  -moz-text-decoration-style: dotted;
  -webkit-text-decoration-color: red;
  -webkit-text-decoration-style: dotted;
}

.dashed-strikethrough {
  text-decoration: line-through;
  text-decoration-color: purple;
  text-decoration-style: dashed;
  -moz-text-decoration-color: purple;
  -moz-text-decoration-style: dashed;
  -webkit-text-decoration-color: purple;
  -webkit-text-decoration-style: dashed;
}

.wavy-strikethrough {
  text-decoration: line-through;
  text-decoration-color: orange;
  text-decoration-style: wavy;
  -moz-text-decoration-color: orange;
  -moz-text-decoration-style: wavy;
  -webkit-text-decoration-color: orange;
  -webkit-text-decoration-style: wavy;
}

.react-tel-input .country-list .country {
  padding-top: 0 !important;
  padding-bottom: 0 !important
}

.react-tel-input input[type=text], .react-tel-input input[type=tel] {
  border: 1px solid #e4e9f0;
  border-radius: 4px;
}

.react-tel-input:hover input[type=text], .react-tel-input:hover input[type=tel] {
  border-color: #4fc2e8;
  outline: 0;
}

.react-tel-input .flag-dropdown {
  border: 1px solid #e4e9f0;
}

.react-tel-input:hover .flag-dropdown, .react-tel-input:focus .flag-dropdown {
  border-color: #4fc2e8;
  outline: 0;
}

.card-container>.ant-tabs-card>.ant-tabs-content {
  margin-top: -16px;
}

.card-container>.ant-tabs-card>.ant-tabs-content>.ant-tabs-tabpane {
  background: #fff!important;
  padding: 16px;
}

.card-container>.ant-tabs-card>.ant-tabs-bar {
  border-color: #fff!important;
}

.card-container>.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab {
  border-color: transparent!important;
  background: transparent!important;
}

.card-container>.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff!important;
  background: #fff!important;
}

.remove-buton-css {
  border-top: none !important;
  padding-top: 0 !important;
  margin: 0 !important;
}

.no-bottom-margin {
  margin-bottom: 0px !important;
}

.custom-form .ant-form-item-label>label::after {
  content: none !important;
}

html {
  height: auto;
}

.advisor-row .ant-descriptions-bordered .ant-descriptions-item-label, .advisor-row .ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 8px 16px !important;
}

.advisor-row .card:last-child, .offeringscard {
  margin-bottom: 0px !important;
}

.theme-text-color {
  color: #2588ac!important;
}

.upload-button-space-remove {
  padding: 0 15px!important;
}

.backgroundWhite {
  background-color: white !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%/* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.metamask-button {
  margin-top: 20px;
  width: 200px;
  height: 49px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(https://raw.githubusercontent.com/MetaMask/TipButton/master/images/1_pay_mm_off.png);
  cursor: pointer;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0.3rem;
}

.metamask-button:hover {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: 0;
  width: 200px;
  height: 49px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(https://raw.githubusercontent.com/MetaMask/TipButton/master/images/1_pay_mm_over.png);
}

.text-white {
  color: white !important;
}

.text-underline {
  text-decoration: underline !important;
}

.transactions-undertaken .ant-checkbox-group-item {
  width: 30% !important;
}

.signaturepad {
  border: 1px solid #e4e9f0 !important;
  border-radius: 4px !important;
}

.linkedin-success-container div {
  color: white !important;
}

.home-heading-text {
  font-size: 2.5rem !important;
}

.home-sub-heading-text {
  font-size: 20px !important;
}

.homepage-custom-header {
  margin-bottom: 17% !important;
}

.homepage-content-container {
  position: relative;
  max-width: 65rem !important;
  text-align: center;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.ctf-header-promo {
  color: white !important;
}

/* .ctf-header, .ctf-recommended {
  margin-bottom: 3.71429rem !important;
} */

.ctf-reviews {
  margin: 0 !important;
  padding: 5.71429rem 0 !important;
  background-color: #F2F4F8 !important;
}

/* .ctf-image {
  padding: 3rem !important;
} */

.ctf-partition-title {
  font-size: 2rem !important;
  font-weight: 500 !important;
  /* margin-bottom: 1rem !important; */
}

.ctf-partition-title-description {
  font-size: 1.28571rem !important;
  line-height: 2.14286rem !important;
}

.contact-us-button-container {
  position: relative !important;
  width: 200px !important;
}

.contact-us-button {
  position: absolute !important;
  top: 50% !important;
  width: auto !important;
  background-color: #648e66 !important;
  border-color: #648e66 !important;
  color: white !important;
}

.ctf-partition-title-description {
  margin-bottom: 0 !important;
}

.feature-offering-container {
  margin-right: 20px !important;
  position: relative !important;
}

.blog-email-input {
  color: black !important;
  border: 1px solid #0b0c22 !important;
  border-radius: 27px !important;
  height: 45px !important;
  width: 100% !important;
  padding-left: 30px !important;
  outline: none !important;
  transition: all .5s ease !important;
}

.subscribe-button {
  position: absolute !important;
  top: 0px !important;
  right: 0px !important;
  background-color: #265A9F !important;
  display: inline-block !important;
  height: 45px !important;
  line-height: 30px !important;
  text-align: center !important;
  -webkit-border-radius: 100px !important;
  -moz-border-radius: 100px !important;
  border-radius: 100px !important;
  border: 1px solid #658f66 !important;
  background: #658f66 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #fff !important;
  letter-spacing: 0.5px !important;
  text-transform: uppercase !important;
  transition: all 0.3s ease 0s !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  outline: none !important;
  cursor: pointer !important;
}

.maksab-button, .maksab-button:focus, .btn.btn-primary:focus, .maksab-button:active {
  border: 1px solid #658f66 !important;
  background: #658f66 !important;
}

.maksab-button-custom-style {
  padding-top: 0px !important;
  border-top: none !important;
}

.ctf-header {
  min-height: 15vh !important;
  padding-top: 2rem !important;
}

.header-access-offering-button {
  margin-right: 1.5rem !important;
}

.required-sign {
  color: red !important;
}

.react-player {
  height: 100% !important;
}

.color-white {
  color: white !important;
}

.color-theme-green {
  color: #658f66 !important;
}

.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon {
  color: #658f66 !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #658f66 !important;
}

.ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-nav .ant-tabs-tab:hover {
  color: #658f66 !important;
}

.ant-tabs-ink-bar {
  background-color: #658f66 !important;
}

.ctf-footer {
  font-size: 14px !important;
  padding: 25px 0 !important;
  /* background-color: white !important; */
}

.ctf-footer-copy-text {
  margin-bottom: 0 !important;
}

.privacy-list-elements {
  padding-left: 13px !important;
}

.privacy-list-elements h6 {
  font-weight: bold !important;
}

.dark-footer-background {
  background-color: #7765AB;
  background-image: linear-gradient(to bottom, #081123, #132548 80%, #7765AB);
  color: white !important;
}

.dark-footer-background a {
  color: white !important;
}

.profile-verified-color {
  color: #648e66 !important;
}

body .ant-popover-buttons .ant-btn.ant-btn-sm {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.video-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(51, 51, 51, 0.4117647058823529);
}

@-webkit-keyframes strims {
  from {
    width: 50px;
    height: 50px;
    opacity: 1
  }
  to {
    width: 144px;
    height: 144px;
    opacity: 0
  }
}

@keyframes strims {
  from {
    width: 50px;
    height: 50px;
    opacity: 1
  }
  to {
    width: 144px;
    height: 144px;
    opacity: 0
  }
}

.video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 157px;
  height: 157px;
  margin-left: -77px;
  margin-top: -77px;
  outline: 0;
  border: 0;
  box-shadow: none !important;
}

.video-link-btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.video-play:before, .video-play:after {
  content: "";
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation: strims 1.5s infinite;
  -o-animation: strims 1.5s infinite;
  animation: strims 1.5s infinite;
  border: 1px solid #fff
}

.video-play:hover:before, .video-play:hover:after {
  -webkit-animation-delay: 0.2s;
  -o-animation-delay: 0.2s;
  animation-delay: 0.2s
}

.video_background {
  position: relative;
  padding-bottom: 200px;
  padding-top: 200px;
}

.modal-video-body {
  max-width: 55% !important;
}

.rtf.open .rtf--mb>* {
  -webkit-transform: inherit !important;
  transform: inherit !important;
}

.rtf button:focus {
  outline: none !important;
}

.header-menu .ctf-nav-item-dropdown-toggle:hover .ctf-nav-sub-list-wrapper, .header-menu .ctf-nav-item-dropdown-toggle.hover .ctf-nav-sub-list-wrapper {
  padding: 10px  20px!important;
  top: inherit !important;
}

.header-menu .ctf-nav-light .ctf-nav-item-dropdown-toggle:hover .ctf-nav-link, .header-menu .ctf-nav-link:hover {
  color:white !important;
}

.header-menu .ctf-nav-sub-item:last-child {
  border-top: none !important;
  margin-top: inherit !important;
  padding-top: inherit !important;
}

.header-menu .ctf-nav-sub-item:first-child{
  border-top: none !important;
  padding-top: 0 !important;
}

.ctf-nav-sub-list-wrapper {
  background-color: #658f66 !important;
  color:white !important;
}

.ctf-nav-sub-list-wrapper .ctf-nav-sub-link {
  color:white !important;
  font-size: 16px !important;
}

.overview-page-container, .cms-page-container {
  font-size: 16px;
}

.overview-page-container .ctf-hire-offer-item-text{
  color: inherit !important;
  font-size: inherit !important; 
}


.platform-page-container .ctf-hire-offer-item-title{
  font-size: 1.25rem !important;
}

.platform-page-container .ctf-hire-offer-icon, .service-page-container .ctf-hire-offer-icon {
  width: 4.21429rem;
  height: 4.21429rem;
}


.ctf-partition-marker::after {
  background-color: #658f66;
}

.nav-center {
  margin-right: 20rem;
  margin-left: 20rem;
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .ctf-header {
    padding-top: 2rem !important;
    margin-bottom: 3.71429rem !important;
  }
  .homepage-custom-header {
    margin-bottom: 10rem !important;
  }
  .home-heading-text {
    font-size: 2rem !important;
  }
  .home-sub-heading-text {
    font-size: 18px !important;
  }
  .ctf-header-title-note {
    font-size: 1rem !important;
    line-height: 2.3rem !important;
  }
  .header-access-offering-button {
    margin-right: 0px !important;
  }
  .ctf-reviews, .ctf-image, .ctf-footer {
    padding: 3.71429rem 0 !important;
  }
  .contact-us-button {
    position: inherit !important;
    top: inherit !important;
    margin-top: 20px !important;
  }
  .ctf-footer-container {
    flex-direction: inherit !important;
  }
  .ctf-footer-container {
    display: inherit !important;
  }
  .ctf-footer-contacts-wrapper, .ctf-footer-contacts-item {
    margin-bottom: 0 !important;
  }
  .ctf-footer-copy-text br {
    display: none !important;
  }
  .contact-btn-wrapper {
    display: block !important;
    text-align: center !important;
  }
  .ctf-partition-title {
    font-size: 1.5rem !important;
  }
  .ctf-nav-sub-list-wrapper {
    background: transparent !important;
  }
  .ctf-nav-sub-list-wrapper .ctf-nav-sub-link{
    color: #6555dc !important;
  }
}