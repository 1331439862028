// @import 'assets/styles/mixins.scss';

// @import  "../style.module.scss";
@import  "../style.module.scss";
.ctfhireoffer {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.ctfHire {
  margin-bottom: 0 !important;
}
.linkHighligher {
  color: #42b3df !important;
}
.cmsContentContianer {
  padding: rem(60) 0;
  height: 100vh;
  background-color: #f2f4f8;
}
