.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector{
    border-color:black !important
}

.loginform {
    .ant-form-item {
        padding-top: 6px !important;
      }  

      .ant-btn:hover, .ant-btn:focus {
        border-color: #0298cf !important
      }
     
} 
.custom-form{
  .ant-form-item{
    .ant-row.ant-form-item-row{
      flex-direction: column ;
      display: flex !important;

    }
    .ant-col.ant-form-item-label{
      text-align: left;
    }
  }
}


