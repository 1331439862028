/* @import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap"); */
// * {
//    margin: 0;
//   padding: 0;
//   outline: none;
//   box-sizing: border-box;
//   font-family: "Poppins", sans-serif; 
// }
body {
  /* height: 100vh; */
  /* width: 100%; */
  // background-image: url("../../../../src/assets/images/bg-img.png");
  background-position: center center;
}

.divBg{
   background-image: url("../../../../src/assets/images/bg-img.png"); 
   background-position: center center;

   display: flex;
   flex-direction: column;
   justify-content: space-between;
   min-height: 100vh;
   background-color: #f2f4f8;
   background-size: cover;
   transition: background-image .4s linear;
    }

.show-btn {
  background: #fff; padding: 10px 20px;
  font-size: 20px;
  font-weight: 500;
  color: #3498db;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.show-btn,
.containerLogin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* input[type="checkbox"] {
  display: none;
} */
.containerLogin {
  min-width: 23.07rem;
  max-width: 38.46rem;
  margin: 0 auto;
  /* padding: 3.84rem 3.07rem 1.53rem; */
  padding: 3.84rem 4.07rem 4.53rem;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;

  /* border-radius: 24px;
  background: #fff;
  width: 410px;
  padding: 30px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1); */
}
/* #show:checked ~ .container {
  display: block;
} */
/* .container .close-btn {
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 18px;
  cursor: pointer;
} */
/* .container .close-btn:hover {
  color: #3498db;
} */
/* .container .text { */
/* font-size: 35px;
  font-weight: 600;
  text-align: center; */
/* } */
.containerLogin form {
  margin-top: -20px;
}
.containerLogin form .data {
  width: 100%;
  margin: 30px 0;
}
form .data label {
  /* font-size: 18px; */
  font-size: 13px;
}
form .data input {
  height: 100%;
  width: 100%;
  padding-left: 10px;
  font-size: 17px;
  border: 1px solid silver;
}
form .data input:focus {
  border-color: #3498db;
  border-bottom-width: 2px;
}
form .forgot-pass {
  margin-top: -8px;
}
form .forgot-pass a {
  color: #3498db;
  text-decoration: none;
}
form .forgot-pass a:hover {
  text-decoration: underline;
}
form .btn {
  margin: 30px 0;
  height: 45px;
  width: 100%;
  position: relative;
  overflow: hidden;
}
form .btn .inner {
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  z-index: -1;
  background: -webkit-linear-gradient(
    right,
    #56d8e4,
    #9f01ea,
    #56d8e4,
    #9f01ea
  );
  transition: all 0.4s;
}
form .btn:hover .inner {
  left: 0;
}
form .btn button {
  height: 100%;
  width: 100%;
  background: none;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
}
form .signup-link {
  text-align: center;
}
form .signup-link a {
  color: #3498db;
  text-decoration: none;
}
form .signup-link a:hover {
  text-decoration: underline;
}

.btn:hover {
  transform: none;
}

.asterisk {
  color: #fb434a;
}

.ant-input-prefix {
  color: rgba(0, 0, 0, 0.25);
}

/* .loginform.ant-input-affix-wrapper { */
.ant-input-affix-wrapper {
  padding: 7px 11px !important;
}

.ant-input-prefix {
  margin-right: 10px !important;
}

.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #bfbfbf !important;
}

.logoDiv {
  padding: 15px;
}

.link{
  color: white;
  margin-left: 10px;
  margin-right: 10px;
}

.payment-check-link{
  margin-left: 10px;
  margin-right: 10px;
}

.login-footer-contant{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}