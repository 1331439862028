.dashboard {
   
    .Card1 {
      background: #99ffcc !important;
    }
  
    .Card2 {
      background: #cce5ff !important;
    }
    .Card3 {
      background: #ffff99 !important;
    }
  
    .pr0 {
      padding-right: 0px !important;
    }
  
    .defaultCursor {
      cursor: default !important;
    }
  
    @media screen and (min-width: 320px) {
      .pt10 {
        padding-top: 10px !important;
      }
    }
  }
  
  .ant-card-extra {
    font-size: 29px !important;
    color: rgb(0 0 0) !important;
  }
  
  .ant-card-body {
    color: black !important;
    border: 2px solid white !important;
  }
  
  .ant-card-head {
    border: 2px solid white !important;
  }