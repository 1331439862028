// @import 'assets/styles/mixins.scss';

@import  "../style.module.scss";

.promo {
  color: $text-darken-more;
  text-align: center;
  margin-bottom: rem(60);

  h1 {
    text-transform: inherit;
    font-weight: 700;
  }

  p {
    font-size: rem(16);
  }
}

.contentHolder {
  text-align: left;
  padding: rem(50) rem(40) rem(20);
  background-color: $white;
  position: relative;
  border-radius: 50px;
}

.mailaddress {
  color: #648e66 !important;
}

.backgroundWhite {
  background-color: white;
}

.ctfhireoffer {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.ctfHire {
  margin-bottom: 0 !important;
}
.cmsContentContianer {
  padding: rem(60) 0;
  height: 100vh;
  background-color: #f2f4f8;
}