.divmain {
    text-align: center;
  }
  .h1 {
    font-size: 100px !important;
  }
  .h2 {
    font-size: 30px !important;
  }

body{
    .notfoundPage{
        background: red !important;
    }
      
}
