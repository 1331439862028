@import '../.././assets/styles/homestyles/mixins.scss';

 // Colors
$white: #fff;

$text: #74708d;
$text-lighten: #c0bdd0;
$text-darken: #615d7c;
$text-darken-more: #514d6a;

$gray: #d2d9e5;
$gray-lighten: #eef0f4;
$gray-lighten-more: #f2f4f8;
$gray-lighten-more-more: #f9fafc;
$gray-darken: #b8beca;
$gray-border: #e4e9f0;

$black: #0e0b20;
$black-lighten: #222034;
$black-lighten-more: #393749;

$blue: #08f;
$blue-darken: #0072d6;
$orange: #f2a654;
$yellow: yellow;

$menuBg: #001529;
$menuBg-darken: #000c17;

// Accent colors
$default: #acb7bf;
$primary: #658f66; //28a9db
$secondary: #6a7a84;
$success: #46be8a;
$info: #0887c9;
$warning: #f39834;
$danger: #fb434a;


.promo {
  color: $text-darken-more;
  text-align: center;
  margin-bottom: rem(60);

  h1 {
    text-transform: inherit;
    font-weight: 700;
  }

  p {
    font-size: rem(16);
  }
}

.contentHolder {
  text-align: left;
  padding: rem(50) rem(40) rem(20);
  background-color: white;
  position: relative;
  border-radius: 50px;
}

.mailaddress {
  color: #648e66 !important;
}

.backgroundWhite {
  background-color: white;
}

.ctfhireoffer {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.ctfHire {
  margin-bottom: 0 !important;

}
