.ant-form-item-explain-error {
  text-align: left;
}
.ant-select-selector {
  padding: unset !important;
}
.ant-select-selection-overflow,
.ant-input {
  padding: 0 16px;
}
.ant-select-selection-item {
  padding: unset !important;
}
.text-center {
  text-align: center;
  padding-top: 1%;
}
.dfc-details-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dfc-title {
  margin-bottom: 0;
}

.receipt-number {
  flex: 1;
  max-width: 300px;
  margin-left: auto;
  font-weight: bold;
}

.profImage {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .qrImage {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 375px) {
  .back-btn,
  .place-order-button,
  .success-btn {
    width: 100%;
    padding: 15px 15px !important;
  }
  .ant-btn:focus,
  .ant-btn:hover {
    border-color: transparent;
  }
}
.form-item-step4 {
  margin-top: 10px;
}
.bankDetails {
  display: flex !important;
  align-items: center !important;
  padding: 0 0 0 52px !important;
}
.formItemWithImagePreview {
  margin-bottom: 0 !important;
}
.hidden {
  display: none !important;
}
.ant-btn-success {
  background-color: rgb(2, 108, 73);
  color: #fff;
}
.removepadding {
  padding: 0 !important;
}

/* CSS changes */
.subscription-container {
  padding: 50px 0px;
}

.mainContainer {
  color: #121212;
  background: #fafafa;
}

.gig-sure-card-wrapper {
  color: #121212;
  background: #fff;
  border: 1px solid #f0f0f0;
}

.checkout-page-container {
  padding: 30px 0 !important;
}
.center-content {
  margin: 0 auto !important;
}

.mt-2 {
  margin-top: 20px !important;
}

.payment-label {
  margin-top: 15px;
  margin-bottom: 0;
}
.payment-method-box {
  border: 1px solid #ebebeb;
  border-radius: 6px !important;
}

.payment-method-box .ant-card-body {
  padding: 15px !important;
}

.payment-method-box.active {
  border-color: #283e45 !important;
}
.subscribe-disabled-btn {
  background: #d6d9eb !important;
}
.back-button-css {
  display: flex !important;
  justify-content: end !important;
  align-items: center;
}
.add-bottom-border {
  border-bottom: solid black 2px;
  padding-bottom: 20px;
}
.coupon-button-css {
  background-color: black !important;
  height: 100%;
  width: 100%;
  color: #ffffff !important;
}
.form-input-border {
  border: solid 1px black !important;
  height: 50px !important;
  border-radius: 4px;
}
.ant-picker-input > input:hover,
.form-input-border:hover {
  border-color: #ffcf30 !important;
}
.ant-select-selector {
  height: 25px !important;
  align-items: center;
}
.ant-input-group-addon
  .ant-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border: unset;
}
.ant-input-group-addon .ant-select-open .ant-select-selector,
.ant-input-group-addon .ant-select-focused .ant-select-selector {
  color: #000 !important;
}
.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border: unset;
  border-left: 1px solid lightgrey;
}
textarea.ant-input,
.field-with-prefix .ant-form-item-control-input {
  border: 1px solid black;
  border-radius: 4px;
}

.field-with-prefix .ant-form-item-control:hover {
  border-color: #ffcf30 !important;
}
.ant-input-group-addon:first-child {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: transparent;
  border: unset;
  border-radius: 4px;
  transition: all 0.3s;
}
.stripe-card-container {
  border: solid 1px black !important;
}
.react-tel-input {
  border: solid 1px black !important;
}
.back-button-color {
  color: black !important;
}
.theme--dark .gig-sure-card-wrapper {
  color: #eee !important;
  background: #343a40 !important;
  border: 1px solid #565656 !important;
}
.plan-card-margin {
  padding: 30px !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 2.5px !important;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding-left: 18px;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #ffcf30 !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #ffcf30 !important;
  box-shadow: none !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #ffcf30 !important;
}

.choose-plan-box {
  height: 140px !important;
  background-color: white !important;
}

.checkout-page-container {
  padding: 30px 0 !important;
}
.plan-title {
  font-size: 24px !important;
  line-height: 32px !important;
  color: #424347;
}

.plan-heading-container {
  border-radius: 6px !important;
  box-shadow: 6px #000000;
  text-align: center;
  box-shadow: 0px 0px 28px rgb(0 0 0 / 5%);
  padding: 40px 0 !important;
}

.plan-title {
  font-size: 24px;
  color: #424347;
}

.popular-flag {
  position: absolute;
  top: -10px;
  left: 40%;
  padding: 0 15px;
  background-color: #cfaa02 !important;
  border-radius: 6px;
  line-height: inherit;
}

.plan-entities-container {
  border-radius: 6px !important;
  box-shadow: 6px #000000;
  text-align: center;
  padding: 15px 24px;
  box-shadow: 0px 0px 28px rgb(0 0 0 / 5%);
}

.choose-plan-container {
  background-color: white;
  text-align: center;
  min-height: 17vh;
  position: relative;
}

.choose-plan-label {
  font-size: 24px;
  padding: 50px 0px;
  color: #424347;
  font-weight: 200 !important;
  font-family: proximanovaregular !important;
}

.tm-bottom-border {
  width: 5%;
  border: 0 none;
  border-bottom-width: 5px;
  border-bottom-style: solid;
  border-bottom-color: rgba(42, 45, 66, 1);
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
}

.plan-timeline-container {
  bottom: 0px;
  font-size: 16px;
  text-align: center !important;
}

.plan-label {
  border-bottom: 5px solid #cfaa02 !important;
  margin-bottom: 5px;
}

.ml-20 {
  margin-left: 20px;
}

.checkout-panel {
  display: flex;
  flex-direction: column;
  width: 940px;
  height: 766px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}
.panel-body {
  padding: 45px 80px 0;
  flex: 1;
}

.title {
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 40px;
  color: #2e2e2e;
}
.progress-bar {
  display: flex;
  margin-bottom: 50px;
  justify-content: space-between;
}

.step {
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  display: block;
  width: 25px;
  height: 25px;
  margin-bottom: 30px;
  border: 4px solid #fff;
  border-radius: 50%;
  background-color: #efefef;
}

.step:after {
  position: absolute;
  z-index: -1;
  top: 5px;
  left: 22px;
  width: 225px;
  height: 6px;
  content: "";
  background-color: #efefef;
}

.step:before {
  color: #2e2e2e;
  position: absolute;
  top: 40px;
}

.step:last-child:after {
  content: none;
}

.step.active {
  background-color: #cfaa02 !important;
}
.step.active:after {
  background-color: #cfaa02 !important;
}
.step.active:before {
  color: #cfaa02 !important;
}
.step.active + .step {
  background-color: #758683;
}
.step.active + .step:before {
  color: #cfaa02 !important;
}

.step:nth-child(1):before {
  content: "Cart";
}
.step:nth-child(2):before {
  right: -40px;
  content: "Confirmation";
}
.step:nth-child(3):before {
  right: -30px;
  content: "Payment";
}
.step:nth-child(4):before {
  right: 0;
  content: "Finish";
}
.payment-method {
  display: flex;
  margin-bottom: 60px;
  justify-content: space-between;
}

.method {
  display: flex;
  flex-direction: column;
  width: 382px;
  height: 122px;
  padding-top: 20px;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 2px;
  background-color: rgb(249, 249, 249);
  justify-content: center;
  align-items: center;
}

.card-logos {
  display: flex;
  width: 150px;
  justify-content: space-between;
  align-items: center;
}

.radio-input {
  margin-top: 20px;
}

input[type="radio"] {
  display: inline-block;
}
.input-fields {
  display: flex;
  justify-content: space-between;
}

.input-fields label {
  display: block;
  margin-bottom: 10px;
  color: #b4b4b4;
}

.info {
  font-size: 12px;
  font-weight: 300;
  display: block;
  margin-top: 50px;
  opacity: 0.5;
  color: #2e2e2e;
}

input[type="text"],
input[type="password"] {
  font-size: 16px;
  width: 100%;
  padding-right: 40px;
  padding-left: 16px;
  color: rgba(46, 46, 46, 0.8);
  border: 1px solid rgb(225, 225, 225);
  border-radius: 4px;
  outline: none;
}

input[type="text"]:focus,
input[type="search"]:focus,
input[type="password"]:focus {
  border-color: #cfaa02 !important;
}

.small-inputs {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}

.small-inputs div {
  width: 182px;
}
.panel-footer {
  display: flex;
  width: 100%;
  height: 96px;
  padding: 0 80px;
  background-color: rgb(239, 239, 239);
  justify-content: space-between;
  align-items: center;
}
.btn {
  font-size: 16px;
  width: 163px;
  height: 48px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  letter-spacing: 1px;
  border: none;
  border-radius: 23px;
}

.back-btn {
  color: #cfaa02 !important;
  background: #fff;
}

.next-btn {
  color: #fff;
  background: #cfaa02 !important;
}

.btn:focus {
  outline: none;
}

.btn:hover {
  transform: scale(1.1);
}
.blue-border {
  border: 1px solid rgb(110, 178, 251);
}
.warning {
  border-color: #cfaa02 !important;
}

.checkout-payment-button {
  background-color: #cfaa02 !important;
  padding: 10px 25px !important;
  border-radius: 6px !important;
  font-size: 16px !important;
  color: #424347 !important;
  height: auto !important;
  border: none !important;
  margin-top: 30px;
}

.stripe-card-container {
  border: 1px solid #e1e1e1;
  padding: 9px !important;
  margin-bottom: 0px !important;
}
.coupon-apply-button {
  border-radius: 6px !important;
  padding: 5px 15px !important;
  height: auto !important;
}

.checkout-form-container input[type="text"],
.checkout-form-container input[type="password"] {
  min-height: 50px;
}

.add-padding {
  padding: 0 20px 0 20px !important;
}
.planDropDown {
  border: solid 1px black;
}
.bill-amount {
  text-align: end;
}
.next-billing-date {
  color: green;
}
.place-order-button {
  background: #cfaa02 !important;
  padding: 15px 60px;
  height: auto !important;
  color: black !important;
}
.button-center {
  display: flex !important;
  justify-content: center !important;
}
.card-background {
  background-color: #eee !important;
}
.add-margin-left {
  margin-left: 2%;
}
.coupon-apply-button {
  min-height: 50px;
}
.bottom-border-in-pay-button {
  border-bottom: solid 1px black;
}
.testing .ant-radio-wrapper {
  width: 100%;
}
.ant-row {
  display: flex;
  flex-flow: row wrap;
}
.ant-col-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}

.ant-input:hover {
  border-color: #ffcf30 !important;
}
.ant-input:focus,
.ant-input-focused {
  box-shadow: none !important;
}

.ant-picker-input {
  min-height: 38px !important;
}

.ant-select-selector {
  min-height: 32px !important;
}
.ant-select-selection-placeholder,
.ant-select-selection-item {
  padding: 0 16px !important;
}
.ant-select-multiple .ant-select-selection-placeholder {
  padding: 0 3px !important;
}

.ant-badge-status-processing {
  background-color: #fff;
}

.ant-badge-status-processing:after {
  border: 1px solid #ffffff00 !important;
}

.back-btn {
  background: #d9d9d9 !important;
  /* background: #3c9cf0 !important; */
  padding: 15px 60px;
  height: auto !important;

  color: #0a0b55 !important;
  /* color: rgb(255, 255, 255) !important; */
}

.ant-btn.back-btn:hover,
.ant-btn.back-btn:focus {
  border-color: #0a0b55 !important;
}
.success-btn {
  background: #0a0b55 ;
  padding: 15prgb (183, 255, 0);
  height: auto !important;
  color: rgb(255, 255, 255) !important;
}

.checkout-form-container input[type="number"] {
  min-height: 50px;
}

.add-bottom-border2 {
  border-bottom: solid black 2px;
}
.add-bottom-border2 .ant-image {
  height: 80px;
}
.add-bottom-border2 .ant-image > img {
  height: 100%;
}

.agreementClass2 > div > div > div {
  margin: 10px;
}

.reCAPTCHAClass > div > div {
  margin: -1px !important;
}
#registrationForm_blood_group .ant-radio-wrapper {
  margin: 0 4px 0 0;
}
.field-with-prefix .ant-select-selector .ant-select-selection-item {
  /* padding: 0 !important; */
  align-items: center;
  text-align: center;
  display: flex;
}
.gig-custom-badge {
  align-items: center;
  display: flex;
}
.gig-registered-success-result .ant-result-subtitle {
  font-size: 16px !important;
}
.ant-upload-list.ant-upload-list-text {
  display: none;
}
.gig-logo {
  text-align: center;
  padding: 15px;
  justify-content: space-evenly;
  display: flex;
}

.ant-checkbox-wrapper {
  margin-top: 10px;
}
.ant-upload.ant-upload-select {
  width: 100%;
}
.ant-upload.ant-upload-select.ant-upload-select-text .ant-btn.ant-btn-default {
  padding: 24px 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid;
  width: 100%;
  border-radius: 4px;
}

.ant-btn:hover,
.ant-btn:focus {
  color: #000;
  border-color: #cfaa02 !important;
}
.image-wrapper {
  position: relative;
  margin-top: 10px;
}
.image-wrapper.pdf:hover::after {
  content: "";
  position: absolute;
  background: rgb(128, 128, 128, 0.2);
  z-index: 11111;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  bottom: 0;
}
.ant-select-multiple .ant-select-selection-item {
  padding: 3px !important;
  font-size: 12px;
  align-items: center;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-picker-status-error.ant-picker,
.ant-picker-status-error.ant-picker:not([disabled]):hover {
  border-color: #000000 !important;
}

.ant-select-selector {
  border: 1px solid !important;
  border-radius: 4px !important;
}

.ant-picker {
  border-radius: 4px !important;
}
.ant-picker-focused {
  box-shadow: 0 0 0 3px #f4ecc6da !important;
}

.prefixSelect > .ant-select-selector {
  border: 0px !important;
}

.ant-radio-inner:after {
  background-color: #cfaa02 !important;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #cfaa02 !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #cfaa02 !important;
}

.ant-radio-wrapper:hover .ant-radio {
  border-color: #cfaa02 !important;
}

.ant-radio-checked:after {
  border: 1px solid #cfaa02 !important;
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px #f4ecc6da !important;
}

.divider{
  margin-bottom: 30px !important;
   font-size: 20px !important;
  color: rgb(10, 13, 85) !important;
  border-top: 0px solid rgb(220, 220, 220)!important;

}

/* .ant-select-status-error {
  border: 1px solid;
}

/* .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #ffffff !important;
} */

html,
.scroll-container {
  overflow: auto;
}

html,
.scroll-container {
  scroll-behavior: smooth;
}
@media (prefers-reduced-motion) {
  html,
  .scroll-container {
    scroll-behavior: auto;
  }
}